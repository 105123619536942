import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { addProductCartAction } from '../../../../../actions/ProductsCartActions'
import medal from '../../../../../assets/icons/medal.svg'
import { ReactComponent as PutOnCart } from '../../../../../assets/icons/putOnCart.svg'
import {
    ProductCategories,
    ProductVariations,
    useAddVisitsProductMutation,
    useCreateCartNotificationMutation,
    useGetAllProductCategoriesQuery,
    useGetUserHasShippingServiceQuery,
    usePurchaseFiltersLazyQuery,
    useValidateConectionQuery,
} from '../../../../../generated/graphql'
import IProductsCart from '../../../../../models/ICart'
import IUserAccount from '../../../../../models/IUserAccount'
import { AppState } from '../../../../../store'
import BargainModal from '../../../BargainModal/BargainModal'
import ContactSellerModal, {
    InterfaceContactSellerModal,
} from '../../../../AdmRegisterCard/NewNotificationModal/ContactSellerModal/ContactSellerModal'
import { MdOutlineLocalShipping } from 'react-icons/md'

type Props = {
    Product: any
    bigOffer?: any
    onClick?: any
    buyWithDirectValue?: boolean
    finalTime?: boolean | undefined
    disableButton: boolean
    disableBid: boolean
}

type ProductsVariation = {
    id: number
    cor: string
    tamanho: string
}

const ProductSaleCardInfos: React.FC<Props> = ({ Product, bigOffer }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const user: IUserAccount = useSelector(
        (state: AppState) => state.user.currentUser
    )
    const productsCart: IProductsCart[] = useSelector(
        (state: AppState) => state.ProductsCart.currentProductsCart
    )
    const variacao: ProductsVariation = {
        id: 0,
        cor: '',
        tamanho: '',
    }
    const [createCartNotification] = useCreateCartNotificationMutation()
    const [addVisitsProduct] = useAddVisitsProductMutation()
    const { data: dataCategories } = useGetAllProductCategoriesQuery()
    const [filterPurchases, { data: dataPurchaseFilters }] =
        usePurchaseFiltersLazyQuery({})
    const [quantityItensBuy, setQuantityItensBuy] = useState(0)
    const { data: getMelhorEnvioServiceConnection } =
        useGetUserHasShippingServiceQuery({
            variables: {
                userId: Product.UserAccount.id,
            },
        })
    useEffect(() => {
        filterPurchases({
            variables: {
                purchaseFiltersInput: {
                    sellerId: Product.UserAccount.id,
                    categories: dataCategories?.getAllProductCategories?.map(
                        (categorie: ProductCategories) => {
                            return categorie.name
                        }
                    ) as any,
                    typeNegotiation: ['Promoção', 'Venda'],
                    order: 'DESC',
                },
            },
        })
    }, [Product])

    const { data: userConection } = useValidateConectionQuery({
        variables: { userId: Product.UserAccount.id },
    })

    const modalRef = useRef<InterfaceContactSellerModal>(null)

    const openModal = useCallback(() => {
        modalRef.current?.showModal()
    }, [modalRef])

    const stockItens = [...Array(Product.stock - 0).keys()].map((x) => x + 1)

    const putOnCartFunc = async (): Promise<void> => {
        if (user) {
            await createCartNotification({
                variables: { userId: user.id, productId: Product.id },
            })

            if (productsCart?.length > 0) {
                const result = productsCart?.find(
                    (product) => product?.parameters?.product?.id === Product.id
                )

                if (!result) {
                    dispatch(
                        addProductCartAction(
                            {
                                installments: 1,
                                parameters: {
                                    product: Product,
                                    quantity: quantityItensBuy || 1,
                                    variacaoId: variacao.id,
                                },
                            },
                            bigOffer
                        )
                    )
                } else {
                    history.push('/buy-address-process-cart')
                }
            } else {
                dispatch(
                    addProductCartAction(
                        {
                            installments: 1,
                            parameters: {
                                product: Product,
                                quantity: quantityItensBuy || 1,
                                variacaoId: variacao.id,
                            },
                        },
                        bigOffer
                    )
                )
            }
        }
    }

    const putOnCartFuncNoUser = async (): Promise<void> => {
        const result = productsCart?.find(
            (product) => product?.parameters?.product?.id === Product.id
        )

        if (!result) {
            dispatch(
                addProductCartAction(
                    {
                        installments: 1,
                        parameters: {
                            product: Product,
                            quantity: quantityItensBuy || 1,
                            variacaoId: variacao.id,
                        },
                    },
                    bigOffer
                )
            )
        }
        history.push('/buy-address-process-cart')
    }

    const disableBargainButton = (): JSX.Element => {
        return !Product.InitialValue ? (
            <></>
        ) : (
            <BargainModal
                ProductMinPrice={Product.InitialValue}
                productId={Product.id}
                product={Product}
            />
        )
    }

    return Product.typeNegotiation === 'Veiculos' ? (
        <>
            <div
                className="state-content"
                style={{
                    width: '100%',
                    height: '100%',
                    paddingTop: '0px',
                    paddingRight: '10px',
                    paddingLeft: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    borderTop: '1px solid black',
                    borderLeft: '1px solid black',
                    borderRight: '1px solid black',
                    borderBottom: 'solid 10px #FFC000',
                }}
            >
                {Product &&
                Product.VehicleInformations &&
                !Product.VehicleInformations.mesesDeContrato ? (
                    <span
                        className="price-content"
                        style={{ fontSize: '12px' }}
                    >
                        {' '}
                        Venda de Veículo - {Product.useState}{' '}
                    </span>
                ) : (
                    <span
                        className="price-content"
                        style={{ fontSize: '12px' }}
                    >
                        {' '}
                        Aluguel de Veículo - {Product.useState}{' '}
                    </span>
                )}
                <div className="price-content">
                    {Product.UserBargains.length > 0 ? (
                        <h2>
                            {Product.price.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                        </h2>
                    ) : (
                        <h2>
                            {Product.price.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                        </h2>
                    )}
                </div>
                <div
                    style={{ paddingTop: '12px', paddingBottom: '12px' }}
                    className="price-content"
                >
                    {Product &&
                    Product.VehicleInformations &&
                    !Product.VehicleInformations.mesesDeContrato ? (
                        <>
                            <span
                                style={{
                                    paddingRight: '5rem',
                                    fontSize: '13px',
                                }}
                            >
                                {' '}
                                Quitado: Sim
                            </span>
                            <span style={{ fontSize: '13px' }}>
                                Ano: {`${Product?.VehicleInformations?.year}`}
                            </span>
                        </>
                    ) : (
                        <>
                            <span
                                style={{
                                    paddingRight: '2.5rem',
                                    fontSize: '13px',
                                }}
                            >
                                Quitado: Não
                            </span>
                            <span style={{ fontSize: '13px' }}>
                                Ano: {`${Product?.VehicleInformations?.year}`}
                            </span>
                        </>
                    )}
                </div>
                <span
                    className={`
                    state-content-promo-header tw-text-smoke-500 tw-line-clamp-2 sm:tw-line-clamp-3
                `}
                >
                    {Product.description.replace(/<.*?>/g, ' ')}
                </span>
                <a href={`/product-view/${Product.id}/all-data#descricao`}>
                    Ver mais
                </a>
            </div>
            <div className="name-content" style={{ padding: 0 }}>
                <div
                    className="name-padding"
                    style={{ padding: 18, marginBottom: '0px' }}
                >
                    <img src={medal} />
                    <div className="name-content-int">
                        <h2
                            onClick={() =>
                                history.push(
                                    `/shopping/${Product.UserAccount.id}/products`
                                )
                            }
                        >
                            {' '}
                            {Product.UserAccount.name.slice(0, 21)}{' '}
                        </h2>
                        <span>
                            {' '}
                            {dataPurchaseFilters?.purchaseFilters.length} vendas
                            realizadas
                        </span>
                        <Link
                            to={`/shopping/${Product.UserAccount.id}/products`}
                            className="go-to"
                        >
                            Visite a loja deste vendedor
                        </Link>
                    </div>
                </div>
                <div
                    className="action-buttons-container"
                    style={{ padding: 9 }}
                >
                    <div className="contact-seller-button-container">
                        <button
                            style={{
                                backgroundColor: '#FFC000',
                                border: '1px solid black',
                                color: 'black',
                            }}
                            onClick={openModal}
                        >
                            Contactar Vendedor
                        </button>
                    </div>
                </div>
                <ContactSellerModal
                    ref={modalRef}
                    contact={Product.UserAccount}
                />
            </div>
        </>
    ) : (
        <>
            {Product.typeNegotiation === 'Imoveis' && (
                <>
                    <div
                        className="state-content"
                        style={{
                            width: '100%',
                            height: '100%',
                            paddingTop: '0px',
                            paddingRight: '10px',
                            paddingLeft: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            borderTop: '1px solid black',
                            borderLeft: '1px solid black',
                            borderRight: '1px solid black',
                            borderBottom: 'solid 10px #05A5BF',
                        }}
                    >
                        {!Product?.PropertyInformations?.mesesDeContrato ? (
                            <span
                                className="price-content"
                                style={{ fontSize: '12px' }}
                            >
                                {' '}
                                Venda de Imóvel - {Product.useState}{' '}
                            </span>
                        ) : (
                            <span
                                className="price-content"
                                style={{ fontSize: '12px' }}
                            >
                                {' '}
                                Aluguel de Imóvel - {Product.useState}{' '}
                            </span>
                        )}
                        <div
                            className="price-content"
                            style={{
                                paddingBottom: '12px',
                                paddingTop: '12px',
                            }}
                        >
                            {Product.UserBargains.length > 0 ? (
                                <h2>
                                    {Product.price.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </h2>
                            ) : (
                                <h2>
                                    {Product.price.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </h2>
                            )}
                        </div>
                        <div className="price-content">
                            {Product?.PropertyInformations.neighborhood &&
                                Product?.PropertyInformations.city && (
                                    <>
                                        <span
                                            style={{
                                                paddingRight: '2.5rem',
                                                fontSize: '13px',
                                            }}
                                        >
                                            {' '}
                                            Bairro:{' '}
                                            {`${Product?.PropertyInformations?.neighborhood}`}
                                        </span>
                                        <span style={{ fontSize: '13px' }}>
                                            Cidade:{' '}
                                            {`${Product?.PropertyInformations?.city}`}
                                        </span>
                                    </>
                                )}
                        </div>
                        <span
                            className={`
                                state-content-promo-header tw-text-smoke-500 tw-line-clamp-2 sm:tw-line-clamp-3
                            `}
                        >
                            {Product.description.replace(/<.*?>/g, ' ')}
                        </span>
                        <a
                            href={`/product-view/${Product.id}/all-data#descricao`}
                        >
                            Ver mais
                        </a>
                    </div>
                </>
            )}

            {Product.typeNegotiation === 'Promoção' && (
                <div className="state-content-promo">
                    <span className="state-content-promo-header">
                        Produto - {Product.useState}
                    </span>
                    <div className="price-content tw-flex tw-flex-col tw-items-start tw-space-y-1">
                        <h2>
                            {Product.price.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                        </h2>
                        <span
                            className={`
                                        tw-flex tw-appearance-none tw-flex-row tw-items-center
                                        tw-space-x-1 tw-rounded tw-bg-primary-500
                                        tw-px-1 tw-py-px tw-text-base tw-font-semibold
                                        tw-text-white
                                    `}
                        >
                            <MdOutlineLocalShipping color="#FFFFFF" size={24} />
                            <label>{`Entrega garantida`}</label>
                        </span>
                    </div>
                    <div className="price-content">
                        <span className="state-content-promo-header">
                            Parcele em até: {Product.maxInstallments}x
                        </span>
                        <p>Estoque: {Product.stock}</p>
                    </div>
                    <span
                        className={`
                            state-content-promo-header tw-text-smoke-500 tw-line-clamp-2 sm:tw-line-clamp-3
                        `}
                    >
                        {Product.description.replace(/<.*?>/g, ' ')}
                    </span>
                    <a href={`/product-view/${Product.id}/all-data#descricao`}>
                        Ver mais
                    </a>
                    <div>
                        {Product.ProductVariations.length > 0 && (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        paddingBottom: '10px',
                                        paddingTop: '5px',
                                    }}
                                >
                                    {Product.ProductVariations.map(
                                        (propColor: ProductVariations) => (
                                            <>
                                                {propColor.ColorVariation
                                                    ?.name && (
                                                    <span
                                                        style={{
                                                            background:
                                                                propColor
                                                                    .ColorVariation
                                                                    .name,
                                                            color: propColor
                                                                .ColorVariation
                                                                .name,
                                                            width: '20px',
                                                            borderRadius:
                                                                '10px',
                                                            marginRight: '5px',
                                                        }}
                                                    >
                                                        O
                                                    </span>
                                                )}
                                            </>
                                        )
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        {Product.ProductVariations.length > 0 && (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {Product.ProductVariations.map(
                                        (propSize: ProductVariations) => (
                                            <>
                                                {propSize.TamanhoVariations
                                                    ?.name && (
                                                    <>
                                                        <span
                                                            style={{
                                                                marginRight:
                                                                    '5px',
                                                                color: 'black',
                                                                fontFamily:
                                                                    'Poppins',
                                                                fontWeight: 600,
                                                                fontSize:
                                                                    '12px',
                                                            }}
                                                        >
                                                            {
                                                                propSize
                                                                    .TamanhoVariations
                                                                    .name
                                                            }
                                                        </span>
                                                    </>
                                                )}
                                            </>
                                        )
                                    )}
                                </div>
                            </>
                        )}
                        {Product.ProductVariations.length > 0 && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Aviso: Variações apenas como exibição,
                                        favor entrar em contato com o vendedor
                                        após a compra para informar a desejada.
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
            {Product.typeNegotiation === 'Venda' && (
                <div className="state-content-sale">
                    <span className="state-content-sale-header">
                        {' '}
                        Produto - {Product.useState}
                    </span>
                    <div className="price-content tw-flex tw-flex-col tw-items-start tw-space-y-1">
                        <h2>
                            {Product.price.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            })}{' '}
                        </h2>
                        <span
                            className={`
                                        tw-flex tw-appearance-none tw-flex-row tw-items-center
                                        tw-space-x-1 tw-rounded tw-bg-primary-500
                                        tw-px-1 tw-py-px tw-text-base tw-font-semibold
                                        tw-text-white
                                    `}
                        >
                            <MdOutlineLocalShipping color="#FFFFFF" size={24} />
                            <label>{`Entrega garantida`}</label>
                        </span>
                        {Product.freeDelivery && (
                            <>
                                <span
                                    className={`
                                        tw-flex tw-appearance-none tw-flex-row tw-items-center
                                        tw-space-x-1 tw-rounded tw-bg-primary-500
                                        tw-px-1 tw-py-px tw-text-base tw-font-semibold
                                        tw-text-white`}
                                >
                                    <label>{`Frete Grátis!`}</label>
                                </span>
                            </>
                        )}
                    </div>
                    <div className="price-content">
                        {Product.maxInstallments > 1 ? (
                            <span className="state-content-sale-header">
                                Parcele em até: {Product.maxInstallments}
                            </span>
                        ) : (
                            <span className="state-content-sale-header">
                                Pague à vista
                            </span>
                        )}
                        <p>Estoque: {Product.stock}</p>
                    </div>
                    <span
                        className={`
                            state-content-sale-header tw-text-smoke-500 tw-line-clamp-2 sm:tw-line-clamp-3
                        `}
                    >
                        {Product.description.replace(/<.*?>/g, ' ')}
                    </span>

                    <a href={`/product-view/${Product.id}/all-data#descricao`}>
                        Ver mais
                    </a>
                    <div>
                        {Product.ProductVariations.length > 0 && (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        paddingBottom: '10px',
                                        paddingTop: '5px',
                                    }}
                                >
                                    {Product.ProductVariations.map(
                                        (propColor: ProductVariations) => (
                                            <>
                                                {propColor.ColorVariation
                                                    ?.name && (
                                                    <span
                                                        style={{
                                                            background:
                                                                propColor
                                                                    .ColorVariation
                                                                    .name,
                                                            color: propColor
                                                                .ColorVariation
                                                                .name,
                                                            width: '20px',
                                                            borderRadius:
                                                                '10px',
                                                            marginRight: '5px',
                                                        }}
                                                    >
                                                        O
                                                    </span>
                                                )}
                                            </>
                                        )
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        {Product.ProductVariations.length > 0 && (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {Product.ProductVariations.map(
                                        (propSize: ProductVariations) => (
                                            <>
                                                {propSize.TamanhoVariations
                                                    ?.name && (
                                                    <>
                                                        <span
                                                            style={{
                                                                marginRight:
                                                                    '5px',
                                                                color: 'black',
                                                                fontFamily:
                                                                    'Poppins',
                                                                fontWeight: 600,
                                                                fontSize:
                                                                    '12px',
                                                            }}
                                                        >
                                                            {
                                                                propSize
                                                                    .TamanhoVariations
                                                                    .name
                                                            }
                                                        </span>
                                                    </>
                                                )}
                                            </>
                                        )
                                    )}
                                </div>
                            </>
                        )}
                        {Product.ProductVariations.length > 0 && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Aviso: Variações apenas como exibição,
                                        favor entrar em contato com o vendedor
                                        após a compra para informar a desejada.
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}

            <div className="name-content tw-p-4">
                {Product.typeNegotiation !== 'Leilao' &&
                    Product.typeNegotiation !== 'Veiculos' &&
                    Product.typeNegotiation !== 'Imoveis' && (
                        <div className="name-padding">
                            <img src={medal}></img>
                            <div className="name-content-int">
                                <h2
                                    onClick={() =>
                                        history.push(
                                            `/shopping/${Product.UserAccount.id}/products`
                                        )
                                    }
                                >
                                    {' '}
                                    {Product.UserAccount.name.slice(0, 21)}{' '}
                                </h2>
                                <span>
                                    {' '}
                                    {
                                        dataPurchaseFilters?.purchaseFilters
                                            .length
                                    }{' '}
                                    vendas realizadas
                                </span>
                                <Link
                                    to={`/shopping/${Product.UserAccount.id}/products`}
                                    className="go-to"
                                >
                                    Visite a loja deste vendedor
                                </Link>
                            </div>
                        </div>
                    )}
                {Product.typeNegotiation === 'Imoveis' && (
                    <>
                        <div
                            className="name-padding"
                            style={{ padding: 18, marginBottom: '0px' }}
                        >
                            <img src={medal} />
                            <div className="name-content-int">
                                <h2
                                    onClick={() =>
                                        history.push(
                                            `/shopping/${Product.UserAccount.id}/products`
                                        )
                                    }
                                >
                                    {' '}
                                    {Product.UserAccount.name.slice(0, 21)}{' '}
                                </h2>
                                <span>
                                    {' '}
                                    {
                                        dataPurchaseFilters?.purchaseFilters
                                            .length
                                    }{' '}
                                    vendas realizadas
                                </span>
                                <Link
                                    to={`/shopping/${Product.UserAccount.id}/products`}
                                    className="go-to"
                                >
                                    Visite a loja deste vendedor
                                </Link>
                            </div>
                        </div>
                        <div
                            className="action-buttons-container"
                            style={{ padding: 9 }}
                        >
                            <div className="contact-seller-button-container">
                                <button
                                    style={{
                                        backgroundColor: '#05A5BF',
                                        border: '1px solid black',
                                    }}
                                    onClick={openModal}
                                >
                                    Contactar Vendedor
                                </button>
                            </div>
                        </div>
                        <ContactSellerModal
                            ref={modalRef}
                            contact={Product.UserAccount}
                        />
                    </>
                )}
                {Product.typeNegotiation !== 'Leilao' &&
                    Product.typeNegotiation !== 'Veiculos' &&
                    Product.typeNegotiation !== 'Imoveis' && (
                        <>
                            <div className="tw-flex tw-flex-col tw-items-center tw-px-8 md:tw-items-start">
                                <span>Quantidade:</span>
                                <select
                                    className="tw-form-select 
                                    tw-form-select-sm tw-font-normal tw-text-gray-700 tw-border-gray-300 tw-focus:text-gray-700 tw-focus:bg-white
                                    tw-focus:border-blue-600 tw-focus:outline-none tw-m-0 tw-block tw-w-2/4 tw-appearance-none
                                    tw-rounded tw-border tw-border-solid tw-bg-white tw-bg-clip-padding tw-bg-no-repeat tw-px-2
                                    tw-py-1 tw-text-sm tw-transition tw-ease-in-out "
                                    onChange={(e) =>
                                        setQuantityItensBuy(
                                            Number.parseInt(e.target.value)
                                        )
                                    }
                                >
                                    {Product &&
                                        stockItens.map((posicao, index) => (
                                            <option value={posicao} key={index}>
                                                {posicao}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="action-buttons-container">
                                <div>
                                    <>
                                        {Product.typeNegotiation !==
                                            'Leilao' && (
                                            <div
                                                className={`button-add-item ${
                                                    productsCart?.find(
                                                        (product) =>
                                                            product?.parameters
                                                                ?.product
                                                                ?.id ===
                                                                Product?.id &&
                                                            Product.active ===
                                                                true
                                                    )
                                                        ? 'active'
                                                        : ''
                                                } ${
                                                    user?.id ===
                                                        Product?.UserAccount
                                                            ?.id &&
                                                    Product.active !== true
                                                        ? 'disabled-add-item'
                                                        : ''
                                                }`}
                                                onClick={() => {
                                                    if (user) {
                                                        if (
                                                            user?.id !==
                                                                Product
                                                                    ?.UserAccount
                                                                    ?.id &&
                                                            Product.active
                                                        ) {
                                                            putOnCartFunc()
                                                        }
                                                    } else {
                                                        if (Product.active)
                                                            putOnCartFuncNoUser()
                                                    }
                                                }}
                                            >
                                                <PutOnCart className="cart-icon" />
                                            </div>
                                        )}

                                        {Product.typeNegotiation !== 'Leilao' &&
                                            Product.typeNegotiation !==
                                                'Veiculos' &&
                                            Product.typeNegotiation !==
                                                'Imoveis' && (
                                                <>
                                                    <div
                                                        className={`button-organizer-cart ${
                                                            user?.id ===
                                                                Product
                                                                    ?.UserAccount
                                                                    ?.id ||
                                                            Product.active ===
                                                                false
                                                                ? 'disabled'
                                                                : ''
                                                        }`}
                                                        onClick={async () => {
                                                            if (
                                                                user &&
                                                                user.id !==
                                                                    Product
                                                                        .UserAccount
                                                                        .id &&
                                                                Product.active
                                                            ) {
                                                                await putOnCartFunc()
                                                                history.push(
                                                                    `/buy-address-process-cart`
                                                                )
                                                            } else if (
                                                                !user &&
                                                                Product.active
                                                            ) {
                                                                putOnCartFuncNoUser()
                                                            }
                                                        }}
                                                    >
                                                        {Product.active ? (
                                                            <span>
                                                                Comprar Agora
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                Produto Inativo
                                                            </span>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                    </>
                                </div>
                                {Product.typeNegotiation === 'Promoção' &&
                                    disableBargainButton()}
                            </div>
                        </>
                    )}
            </div>
        </>
    )
}

export default ProductSaleCardInfos
